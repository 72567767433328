export default {
  namespaced: true,
  state: {
    // view_simulation
    btn_header: {
      show: false,
      showFilter: false,
      data: {}
    }
  },
  mutations: {
    // view_simulation
    SIMULATION_SHOW_BTN(state, show = false) {
      state.btn_header.show = show;
    },
    BTN_HEADER_SHOW_FILTER(state, show = false) {
      state.btn_header.showFilter = show;
    },
    BTN_HEADER_data(state, data) {
      state.btn_header.data = data;
    },
  },
  actions: {},
}