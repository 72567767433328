import Vue from 'vue'
import VueRouter from 'vue-router'
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import Visuals from './routes/visuals'
import Simulator from './routes/simulator'
import DataQuality from './routes/dataquality'
import Users from './routes/users'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        action: 'read',
        resource: 'Sop',
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    ...Users,
    ...Visuals,
    ...Simulator,
    ...DataQuality,
    {
      path: '/data-gathering/details/:id',
      name: 'data-gathering-details',
      component: () => import('@/modules/data-gathering/DataGatheringDetails.vue'),
      meta: {
        action: 'read',
        resource: 'Sop',
        pageTitle: 'Detalle',
        breadcrumb: [
          {
            text: 'Home',
            to: '/',
          },
          {
            text: 'Detalle',
            active: true,
          },
        ],
      },
    },
    {
      path: '/settings-sop',
      name: 'settings-sop',
      component: () => import('@/modules/settings/SopSettings.vue'),
      meta: {
        action: 'read',
        resource: 'Admin',
        pageTitle: 'Settings SOP',
        breadcrumb: [
          {
            text: 'Settings SOP',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'auth-login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/pages/miscellaneous/coming-soon',
      name: 'misc-coming-soon',
      component: () => import('@/views/miscellaneous/ComingSoon.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'Auth',
      },
    },
    {
      path: '/pages/miscellaneous/not-authorized',
      name: 'misc-not-authorized',
      component: () => import('@/views/miscellaneous/NotAuthorized.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'Auth',
      },
    },
    {
      path: '/pages/miscellaneous/under-maintenance',
      name: 'misc-under-maintenance',
      component: () => import('@/views/miscellaneous/UnderMaintenance.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'Auth',
      },
    },
    {
      path: '/pages/miscellaneous/error',
      name: 'misc-error',
      component: () => import('@/views/miscellaneous/Error.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'Auth',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'Auth',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next(getHomeRouteForLoggedInUser())
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
