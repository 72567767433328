import Vue from "vue"
import VueApollo from "vue-apollo"
import ApolloClient from "apollo-client"
// import { HttpLink } from "apollo-link-http"
import { InMemoryCache } from "apollo-cache-inmemory"
// import { split, ApolloLink } from 'apollo-link'
import { WebSocketLink } from 'apollo-link-ws'
// import { getMainDefinition } from 'apollo-utilities'
import useJwt from '@/auth/jwt/useJwt'
// import { setContext } from 'apollo-link-context'
// import { onError } from 'apollo-link-error'
// import { SubscriptionClient } from 'subscriptions-transport-ws'

Vue.use(VueApollo)

const getHeaders = () => {
  const headers = {}
  const token = useJwt.getToken()
  console.log("getHeaders", token);
  if (token) {
    headers.Authorization = `Bearer ${token}`
    console.log('headers.Authorization :', headers.Authorization)
  }
  return headers
}

const getNewToken = async () => {
  let resp = await useJwt.refreshToken()
  console.log("getNewToken", resp);
  useJwt.setToken(resp.data.accessToken)
  useJwt.setRefreshToken(resp.data.refreshToken)
}

// Create an http link:
// const httpLink  = new HttpLink({
//   uri: process.env.VUE_APP_BASE_URL_HASURA,
//   fetch,
//   headers: getHeaders()
// })

// Create a WebSocket link:
const link = new WebSocketLink({
  uri: process.env.VUE_APP_BASE_URL_HASURA_WSS,
  options: {
    reconnect: true,
    timeout: 30000,
    lazy: true,
    connectionParams: async () => {
      await getNewToken()
      return { headers: getHeaders() }
    },
  }
})

const defaultClient = new ApolloClient({
  link: link,
  cache: new InMemoryCache({
    addTypename: true
  }),
  connectToDevTools: true
})

export default new VueApollo({
  defaultClient: defaultClient,
})
