export default [
  {
    path: '/simulator/simulador',
    name: 'simulador',
    component: () => import('@/modules/simulator/Simulador.vue'),
    meta: {
      action: 'read',
      resource: 'Sop',
    },
  },
  {
    path: '/simulator/simulador2',
    name: 'simulador2',
    component: () => import('@/modules/simulator/Simulador2.vue'),
    meta: {
      action: 'read',
      resource: 'Sop',
    },
  },
  {
    path: '/simulator/simulador3',
    name: 'simulador3',
    component: () => import('@/modules/simulator/Simulador3.vue'),
    meta: {
      action: 'read',
      resource: 'Sop',
    },
  },
  {
    path: '/simulator/tabla-post-simulacion',
    name: 'tabla-post-simulacion',
    component: () => import('@/modules/simulator/TablaPostSimulacion.vue'),
    meta: {
      action: 'read',
      resource: 'Sop',
    },
  }

]